$(document).ready(function() {
	$('[data-scroll]').click(function(event) {
		event.preventDefault();

		var t = $(this).attr('href');

		console.log($(t).offset().top);

		$('html, body').animate({
			scrollTop: $(t).offset().top
		}, 800, function(){});	
	});
});